export const seoTitle = '三维城市气象仿真系统 | 产品服务 | 赑玄阁';
export const seoDescription = '三维城市气象仿真系统可以进行雷达仿真处理，根据雷达回波三维生成可视化模拟云层。该系统同时也可进行仿真场景，展现出身临其境超真实游戏光影天气场景。';
export const browserTitle = '三维城市气象仿真系统 | 产品服务 | 赑玄阁';
export const keywords = '气象仿真, 城市天气, 三维城市, 雷达仿真, 模拟云, 天气模拟, 仿真系统';


export const images = [
  {
    imgPath: require('../../images/products/city3d/11.jpg').default,
  },
  {
    imgPath: require('../../images/products/city3d/22.jpg').default,
  },
  {
    imgPath: require('../../images/products/city3d/33.jpg').default,
  },
  {
    imgPath: require('../../images/products/city3d/44.jpg').default,
  },
  {
    imgPath: require('../../images/products/city3d/55.jpg').default,
  },
  {
    imgPath: require('../../images/products/city3d/66.jpg').default,
  },
  {
    imgPath: require('../../images/products/city3d/77.jpg').default,
  },
];
