import * as React from "react"
import Helmet from "react-helmet";
import Layout from '../../components/Layout'
import { styled, useTheme } from '@mui/material/styles'
import Box from "@mui/material/Box";
import ProjectHeader from "../../components/Common/ProjectHeader";
import {browserTitle, seoDescription, seoTitle, images, keywords} from "../../data/products/city3d";
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import MobileStepper from '@mui/material/MobileStepper';

import {
  BackBox, Box4Title, CennterBox, ProductContact,
  SubTitleBox,
  SubTitleEnBox,
  SubTitleWhiteBox,
  TitleBox,
  TitleEnBox
} from "../../components/Common/Base";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid/Grid";

const MyImg = styled('img')(() => {
  return {
    width: '100%',
    verticalAlign: 'bottom',
  }
});

const ImgButtonLeft = styled(Button)(({theme}) => ({
  position: 'absolute',
  top: '50%',
  left: '5vw',
  marginTop: '-40px',
}));

const ImgButtonRight = styled(Button)(({theme}) => ({
  position: 'absolute',
  top: '50%',
  right: '5vw',
  marginTop: '-40px',
}));

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const City3d = (props) => {
  const { location } = props;

  const theme = useTheme();

  const [activeStep, setActiveStep] = React.useState(0);

  const maxSteps = images.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const eleShow = React.useRef();

  const handleNextClick = () => {
    eleShow.current && eleShow.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    })
  };

  return (
    <Layout location={location}>
      <Helmet>
        <meta name="title" content={seoTitle} />
        <meta name="description" content={seoDescription} />
        <meta name="keywords" content={keywords} />
        <title>{browserTitle}</title>
      </Helmet>
      <ProjectHeader
        // backVideo={require('../../images/products/city3d/01.mp4').default}
        backImg={require('../../images/products/city3d/city01.jpg').default}
        backVideo={'products/city3d/01.m3u8'}
        title={'城市天气数字孪生系统'}
        titleEn={'Three-dimensional urban weather simulation system'}
        titleEn2={'Urban Weather Digital Twin System'}
        subTitle={'三维城市气象仿真系统'}
        gridShow
        handleNextClick={handleNextClick}
      />
      {/* box2 */}
      <BackBox
        ref={eleShow}
        sx={{
          backgroundImage: {
            xs: ``,
            sm: `url(${require('../../images/products/city3d/03.jpg').default})`,
          },
          pt: {
            xs: 6,
            sm: 12,
            md: 18,
            lg: 24
          },
          pb: {
            xs: 6,
            sm: 12,
            md: 18,
            lg: 24
          }
        }}
      >
        <Container
          maxWidth="xl"
        >
          <Grid
            sx={{
              height: '45vw',
              alignItems: 'center',
            }}
            container
            spacing={4}
            alignItems="center"
          >
            <Grid
              item
              xs={12}
              sm={6}
            >
              <Box4Title
                variant="h5"
                sm={{
                  textAlign: {
                    xs: 'center',
                    sm: 'left',
                  }
                }}
              >
                雷达仿真处理
              </Box4Title>
              <TitleEnBox
                gutterBottom
                variant="h6"
                sm={{
                  textAlign: {
                    xs: 'center',
                    sm: 'left',
                  },
                  pb: {
                    xs: 2,
                    md: 4,
                  },
                }}
              >
                Processing Method of 3D Radar
              </TitleEnBox>
              <Box
                sx={{
                  display: {
                    xs: 'none',
                    sm: 'block',
                  }
                }}
              >
                <SubTitleWhiteBox
                  variant="h5"
                  gutterBottom
                  sx={{
                    fontWeight: '100',
                    textAlign: {
                      xs: 'center',
                      sm: 'left',
                    },
                  }}
                >
                  根据雷达回波, 三维生成可视化模拟云层。
                </SubTitleWhiteBox>
                <SubTitleEnBox
                  gutterBottom
                  variant="h6"
                  sx={{
                    textAlign: {
                      xs: 'center',
                      sm: 'left',
                    }
                  }}
                >
                  Based on the radar echoes, a three-dimensional visualization of the simulated cloud cover is generated.
                </SubTitleEnBox>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <img alt="" width='100%' src={require('../../images/products/city3d/02.jpg').default} />
            </Grid>
          </Grid>
        </Container>
      </BackBox>
      {/* box black */}
      <Box sx={{
        display: {
          xs: 'block',
          sm: 'none',
          pb: {
            xs: 4,
            md: 8,
            lg: 16,
          },
        }
      }}>
        <Container
          maxWidth="xl"
          sx={{p: 2}}
        >
          <SubTitleWhiteBox
            variant="h5"
            gutterBottom
            sx={{
              fontWeight: '100',
              textAlign: {
                xs: 'center',
              },
            }}
          >
            根据雷达回波, 三维生成可视化模拟云层。
          </SubTitleWhiteBox>
          <SubTitleEnBox
            gutterBottom
            variant="h6"
            sx={{
              textAlign: {
                xs: 'center',
              }
            }}
          >
            Based on the radar echoes, a three-dimensional visualization of the simulated cloud cover is generated.
          </SubTitleEnBox>
        </Container>
      </Box>
      {/* box3 */}
      <Box
        sx={{
          pt: {
            xs: 4,
            md: 8,
            lg: 16,
          },
        }}
      >
        <Box4Title
          variant="h5"
          align="center"
        >
          仿真场景
        </Box4Title>
        <TitleEnBox
          gutterBottom
          variant="h6"
          align="center"
          sx={{
            pb: {
              xs: 2,
              md: 4,
            },
          }}
        >
          Simulation Scenes
        </TitleEnBox>
        <Grid sx={{mb: 4}} container spacing={0}>
          <Grid
            item
            xs={6}
            sm={3}
          >
            <MyImg alt="" width="100%" src={require('../../images/products/city3d/041.jpg').default} />
          </Grid>
          <Grid
            item
            xs={6}
            sm={3}
          >
            <MyImg alt="" width="100%" src={require('../../images/products/city3d/042.jpg').default} />
          </Grid>
          <Grid
            item
            xs={6}
            sm={3}
          >
            <MyImg alt="" width="100%" src={require('../../images/products/city3d/043.jpg').default} />
          </Grid>
          <Grid
            item
            xs={6}
            sm={3}
          >
            <MyImg alt="" width="100%" src={require('../../images/products/city3d/044.jpg').default} />
          </Grid>
        </Grid>
      </Box>
      {/* box black */}
      <Box sx={{
        display: {
          xs: 'block',
        },
        pb: {
          xs: 4,
          md: 8,
          lg: 16,
        },
      }}>
        <Container
          maxWidth="xl"
          sx={{pb: 2}}
        >
          <SubTitleWhiteBox
            variant="h5"
            gutterBottom
            sx={{
              fontWeight: '100',
              textAlign: {
                xs: 'center',
              },
            }}
          >
            身临其境超真实游戏光影天气场景
          </SubTitleWhiteBox>
          <SubTitleEnBox
            gutterBottom
            variant="h6"
            sx={{
              textAlign: {
                xs: 'center',
              }
            }}
          >
            Immersive and ultra-realistic game light and weather scenes
          </SubTitleEnBox>
        </Container>
      </Box>
      {/* box4 */}
      <Box
        sx={{
          pt: {
            xs: 4,
            md: 8,
            lg: 16,
          },
          pb: {
            xs: 2,
            md: 3,
            lg: 4,
          }
        }}
      >
        <Box4Title
          variant="h5"
          align="center"
        >
          天气模拟变化
        </Box4Title>
        <TitleEnBox
          gutterBottom
          variant="h6"
          align="center"
          sx={{
            pb: {
              xs: 2,
              md: 4,
            },
          }}
        >
          Weather Changes Simulation
        </TitleEnBox>
        <Box sx={{
          position: 'relative',
          // width: '90vw',
          paddingLeft: '10vw',
          paddingRight: '10vw',
        }}>
          <AutoPlaySwipeableViews
            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            index={activeStep}
            onChangeIndex={handleStepChange}
            enableMouseEvents
          >
            {images.map((step, index) => (
              <div key={'img-' + index}>
                {
                  Math.abs(activeStep - index) <= 2 ? (
                    <Box
                      component="img"
                      sx={{
                        height: '45vw',
                        display: 'block',
                        overflow: 'hidden',
                        width: '100%',
                      }}
                      src={step.imgPath}
                      alt='天气模拟变化--赑玄阁'
                    />
                  ) : null
                }
              </div>
            ))}
          </AutoPlaySwipeableViews>
          <MobileStepper
            steps={maxSteps}
            position="static"
            activeStep={activeStep}
            nextButton={
              <div />
            }
            backButton={
              <div />
            }
          />
          <ImgButtonRight
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            {theme.direction === 'rtl' ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </ImgButtonRight>
          <ImgButtonLeft size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === 'rtl' ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
          </ImgButtonLeft>
        </Box>
      </Box>
      {/* box black */}
      <Box sx={{
        display: {
          xs: 'block',
        },
        pb: {
          xs: 4,
          md: 8,
          lg: 16,
        },
      }}>
        <Container
          maxWidth="xl"
          sx={{p: 2}}
        >
          <SubTitleWhiteBox
            variant="h5"
            gutterBottom
            sx={{
              fontWeight: '100',
              textAlign: {
                xs: 'center',
              },
            }}
          >
            3D可视化展示，天气效果无缝切换
          </SubTitleWhiteBox>
          <SubTitleEnBox
            gutterBottom
            variant="h6"
            sx={{
              textAlign: {
                xs: 'center',
              }
            }}
          >
            3D visual display with seamless switching of weather effects
          </SubTitleEnBox>
        </Container>
      </Box>
      {/* box5 */}
      <ProductContact />
    </Layout>
  )
}


export default City3d;
